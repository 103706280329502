import { Method, request, Url } from '@/services/base';

async function getClinicCities() {
    return await request(Url.clinic.getClinicCities, Method.get);
}

async function getClinics({ city, clinicType }) {
    return await request(Url.clinic.getClinics, Method.get, { city, clinicType });
}

export { getClinicCities, getClinics };
