<!--服务预约-->
<template>
    <div class="serviceAppointments" :style="serviceAppointments">
        <common-nav-bar title="服务预约" color="#333"></common-nav-bar>
        <van-cell-group inset class="m0">
            <van-cell class="borderBottom">
                <template #title>
                    <span class="tplMinor">服务中心</span>
                    <!--<span class="tplMain">{{ curCity }}</span>-->
                </template>
                <template #default>
                    <div @click="toSelectClinicPage">
                        <span class="tplMain">{{ curClinic.name }}</span>
                        <van-icon class="tplMain" name="exchange"></van-icon>
                    </div>
                </template>
            </van-cell>
            <van-cell class="borderBottom" value-class="">
                <template #default>
                    <van-icon name="location"></van-icon>
                    <span v-if="!distance" class="bookingEmpty">计算距离中... </span>
                    <span v-if="distance" class="bookingEmpty">距您{{ distance }}km </span>
                    <span class="bookingEmpty">{{ curClinic.address }} </span>
                    <span class="guidance" @click="openLocation(item)"
                        ><van-icon name="guide-o" />去这里</span
                    >
                </template>
            </van-cell>
        </van-cell-group>

        <div class="instrumentCard mt10" v-if='curSn'>
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">ID</div>
                <div class="cardTileRight cellFlex-1 textL">{{ curSn }}</div>
            </div>
            <div class="cardBody">
                <div class="userInfo">
                    <van-cell-group inset>
                        <van-cell class="bgNo" center>
                            <template #icon>
                                <van-image
                                    :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                    width="35"
                                />
                            </template>
                            <template #title>
                                <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                <van-icon
                                    :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                    class="sex-icon"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>

        <div class="timeChoose">
            <div class="timeChooseTitle">选择预约时间段</div>
            <van-tabs
                swipeable
                color="#4AB8AB"
                title-active-color="#4AB8AB"
                title-inactive-color="#282A2D"
            >
                <van-tab v-for="(item, i) in clinicWeeks" :key="item.day">
                    <template #title>
                        <div class="textC">{{ item.week }}</div>
                        <div class="textC">{{ item.day }}</div>
                    </template>
                    <div class="chooseBody">
                        <van-radio-group v-model="selectP">
                            <van-row gutter="10">
                                <van-col span="12" v-for="p in periods" :key="p.k">
                                    <van-cell shape="square" checked-color="#4AB8AB">
                                        <template #title>
                                            <span v-if="item[p.k + 'Can']" class="radioText mr10"
                                                >可约</span
                                            >
                                            <span v-if="!item[p.k + 'Can']" class="radionNo mr10"
                                                >已满</span
                                            >
                                            <span class="radionTime">{{ p.label }}</span>
                                        </template>
                                        <template #right-icon>
                                            <van-radio
                                                :disabled="!item[p.k + 'Can']"
                                                :name="i + '-' + p.k"
                                                shape="square"
                                                icon-size="16px"
                                                checked-color="#4AB8AB"
                                            ></van-radio>
                                        </template>
                                    </van-cell>
                                </van-col>
                            </van-row>
                        </van-radio-group>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-cell-group>
            <van-cell class="textC bgNo" size="lager">
                <template #title>
                    <van-button
                        class="newBook plr40"
                        color="#4AB8AB"
                        size="normal"
                        @click="reserveClinic"
                        >预约
                    </van-button>
                </template>
            </van-cell>
        </van-cell-group>
        <van-popup v-model="showCityPopup" position="bottom">
            <van-picker
                show-toolbar
                :columns="cities"
                @confirm="pickCity"
                @cancel="showCityPopup = false"
            />
        </van-popup>
        <van-popup v-model="showClinicPopup" position="bottom">
            <div class="patientInfo">
                <van-cell-group inset class="mtb10 bgNo">
                    <van-cell
                        v-for="(item, i) in clinics"
                        :key="i"
                        :class="
                            i === chooseClinicIndex
                                ? 'borderDefault mb10 chooseBox'
                                : 'borderDefault mb10'
                        "
                        :title="item.name"
                        title-class="tplTitle"
                        @click="chooseClinic(i)"
                    >
                        <template #label>
                            <van-icon name="location" class=""></van-icon>
                            <span class="tplTextDefault">距您36km</span>
                            <span class="tplTextDefault">{{ item.address }}</span>
                        </template>
                    </van-cell>
                    <van-cell class="textC bgNo" size="lager">
                        <template #title>
                            <van-button
                                class="newBook plr40"
                                color="#4AB8AB"
                                size="normal"
                                @click="confirmChooseClinic"
                                >确定
                            </van-button>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
        </van-popup>
    </div>
</template>
<script>
import { getClinicCities, getClinics } from '@/services/clinic';
import { calculateDistance, toast } from '@/util';
import { getClinicWeeks, reserveClinic } from '@/services/reservation';
import CommonNavBar from '@/components/common-nav-bar';
import wx from 'weixin-js-sdk';
import locationMixin from '../../../mixins/locationMixin';

const periods = [
    { k: 'p1', label: '08:30-09:30' },
    { k: 'p2', label: '09:30-10:30' },
    { k: 'p3', label: '10:30-11:30' },
    { k: 'p4', label: '13:00-14:00' },
    { k: 'p5', label: '14:00-15:00' },
    { k: 'p6', label: '15:00-16:00' },
    { k: 'p7', label: '16:00-17:00' },
    { k: 'p8', label: '17:00-18:00' },
];
let selectedClinic;
let selectedCity;
export default {
    name: 'clinic-reservation',
    components: { CommonNavBar },
    mixins:[locationMixin],
    data() {
        return {
            curSn: '',
            curSnUser: {},
            periods,
            cities: [],
            clinics: [],
            curCity: '',
            curClinic: {},
            showCityPopup: false,
            showClinicPopup: false,
            chooseClinicIndex: 0,
            clinicWeeks: [],
            selectP: '',
            serviceAppointments: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            userPhoto: require('@/assets/images/userPhoto.png'),
            distance: '',
        };
    },
    beforeRouteEnter(to, from, next) {
        selectedCity = from.params.selectedCity;
        selectedClinic = from.params.selectedClinic;
        if (selectedClinic) {
            selectedClinic = JSON.parse(selectedClinic);
        }
        next(); // 必需调用
    },
    async created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
        await this.getCities();
        if (this.$store.state.location) {
            this.calcDistance();
        } else {
            let location = await this.getLocation();
            this.$store.commit('updateLocation', location);
            this.calcDistance();
        }
    },
    methods: {
        async getCities() {
            const { code, message, data } = await getClinicCities();
            if (!code) {
                return toast(message);
            }
            this.cities = data;
            this.curCity = selectedCity || data[0];
            await this.getClinics();
        },
        async getClinics() {
            const { code, message, data } = await getClinics({ city: this.curCity});
            if (!code) {
                return toast(message);
            }
            if (data.length) {
                this.clinics = data;
                this.curClinic = selectedClinic || data[0];
                await this.getClinicWeeks();
            }
        },
        async pickCity(val) {
            this.curCity = val;
            await this.getClinics();
            this.showCityPopup = false;
        },
        toSelectClinicPage() {
            this.$router.push({
                path: '/patient/reservation/clinic/select',
                query: { city: this.curCity, clinicId: this.curClinic.id },
            });
        },
        chooseClinic(i) {
            this.chooseClinicIndex = i;
        },
        async confirmChooseClinic() {
            this.curClinic = this.clinics[this.chooseClinicIndex];
            await this.getClinicWeeks();
            this.showClinicPopup = false;
        },
        async getClinicWeeks() {
            this.selectP = '';
            const { code, message, data } = await getClinicWeeks({ clinicId: this.curClinic.id });
            if (!code) {
                return toast(message);
            }
            this.clinicWeeks = data;
        },
        async reserveClinic() {
            if (!this.selectP) {
                return toast('请选择预约时间段');
            }
            const sn = localStorage.getItem('curSn');
            const [i, p] = this.selectP.split('-');
            const day = new Date(new Date().getFullYear() + '-' + this.clinicWeeks[i].day);
            const period = Number(p[1]);
            const { code, message } = await reserveClinic({
                clinicId: this.curClinic.id,
                sn,
                day,
                period,
            });
            if (!code) {
                return toast(message);
            }
            toast('预约成功');
            await this.$router.back();
        },
        calcDistance() {
            const { lng, lat } = this.curClinic;
            let { longitude, latitude } = this.$store.state.location;
            const distance = calculateDistance(longitude, latitude, lng,lat);
            if (distance) this.distance = distance;
        },
        openLocation() {
            const { name, address, lng, lat } = this.curClinic;
            if (!lng || !lat) {
                return toast('服务中心位置信息缺失，无法导航');
            }
            wx.openLocation({
                latitude: lat, // 纬度，浮点数，范围为90 ~ -90
                longitude: lng, // 经度，浮点数，范围为180 ~ -180。
                name, // 位置名
                address, // 地址详情说明
                scale: 28, // 地图缩放级别,整型值,范围从1~28。默认为最大
                infoUrl: '', // 在查看位置界面底部显示的超链接,可点击跳转
            });
        },
    },
};
</script>

<style scoped>
.serviceAppointments {
    padding: 10px;
    box-sizing: border-box;
}

.bookCard {
    padding: 10px 0;
}

.borderBottom.van-cell::after {
    border-bottom: 1px solid #bebebe;
}

.bookCard .van-cell {
    font-size: 12px;
    padding: 8px 16px;
}

.tplMinor {
    color: #acacac;
}

.tplMain {
    color: #282a2d;
}

.bookingEmpty {
    color: #989898;
}

.BookingHave {
    color: #4ab8ab;
}

.newBook {
    height: 35px;
}

.tplButton {
    font-size: 12px;
    border: none;
    text-decoration: underline;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
}

.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #bebebe;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.patientInfo {
    background: #f6f6f8;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
}

.borderDefault.chooseBox {
    border: 1px solid #00c3ae;
    overflow: hidden;
    border-radius: 8px;
}

.tplSerial {
    padding-right: 10px;
}

.tplSerial,
.tplTitle {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
}

.tplTextDefault {
    font-size: 12px;
    margin-left: 5px;
    color: #acacac;
    font-weight: 100;
}

.chooseBox .tplSerial,
.chooseBox .tplTitle {
    color: #00c3ae;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.tplTextEmpty {
    font-size: 18px;
    color: #acacac;
    font-weight: 600;
}

.timeChoose {
    padding: 10px 0 0 0;
}

.timeChooseTitle {
    color: #282a2d;
    font-size: 15px;
    margin-bottom: 10px;
    padding: 10px 15px 0px;
    font-weight: 600;
}

.timeChoose .van-tab {
    font-size: 12px;
}

.timeChoose .van-tabs__nav--line.van-tabs__nav--complete {
    padding-top: 10px;
}

.timeChoose .van-tabs__nav {
    background: none;
}

.timeChoose .van-tabs__content {
    margin-top: 10px;
}

.timeChoose .van-tabs__wrap {
    padding: 0 15px;
}

.timeChoose .chooseBody {
    padding: 10px 15px;
    background-color: #fff;
    overflow: hidden;
}

.timeChoose .van-cell {
    padding: 8px 16px;
    border: 1px solid #979797;
    border-radius: 5px;
    margin: 5px 0;
}

.timeChoose .van-cell__title {
    font-size: 12px;
    color: #282a2d;
}

.radioText {
    color: #4ab8ab;
}

.radionNo {
    color: #888888;
}

.img-icon {
    width: 16px;
}

.flexAuto .van-cell__title,
.flexAuto .van-cell__value {
    flex: auto;
}

.events {
    background: #fff;
    margin: 10px 16px;
    border-radius: 10px;
    overflow: hidden;
}

.events .van-card__header {
    display: block;
}

.eventsState {
    padding: 10px 0;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
}

.events .van-card__title {
    font-size: 15px;
    color: #282a2d;
    line-height: 35px;
}

.events .van-cell {
    padding: 0;
    font-size: 12px;
    color: #6d7278;
    line-height: 24px;
}

.events .van-card__thumb img {
    border-radius: 0;
}

.userInfo {
    padding: 10px;
}

.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}

.userName {
    font-size: 16px;
    color: #282a2d;
}

.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}

.van-nav-bar {
    width: calc(100% - 20px);
}
.guidance {
    font-size: 12px;
    color: #444;
    font-weight: bold;
    padding: 0 5px;
}
</style>
